<template>
  <v-row class="mt-10">
    <v-col lg="7" xl="5" class="mx-auto">
      <h2 class="headline primary--text">
        <v-btn title="Voltar para contactos" icon to="/contacts" exact>
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        {{ __('Novo contato', 'contact') }}
      </h2>
      <v-divider class="mb-9"></v-divider>
      <new-contact-form></new-contact-form>
    </v-col>
  </v-row>
</template>

<script>
import NewContactForm from "../components/forms/NewContactForm";
import i18n from '@/mixins/i18n';

export default {
  components: { NewContactForm },
  mixins: [i18n],
  methods: {
    capitalize: function (s) {
      if (typeof s !== "string") return "";
      return s.charAt(0).toUpperCase() + s.slice(1);
    },
  },
};
</script>